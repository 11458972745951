import React, { useState, useEffect } from 'react';
import inputConfig from './inputConfig.json';
import provincias from './provincias.json';
import oficinas from './oficinas.json';
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from '@material-ui/core';
import {TextField} from '@material-ui/core';
import DatePicker from "react-datepicker";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import { MenuProps} from "./utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function Modal({ closeModal, data }) {
  return (
    <div className="modal-container">
      <div className="modal">
      <Button variant="contained" color="primary" onClick={() => closeModal()}>
          X
        </Button>
        <div className="modal__body">
          <h1 className="modal__title">
            Datos Cita:
          </h1>
          <ul>
            {Object.keys(data).map(option => (
            <li>{option + ": " + data[option]}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function App() {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedProvinceValue, setSelectedProvinceValue] = useState('');
  const [formData, setFormData] = useState({"Provincia cita": []});
  const [mandatoryFormData, setmandatoryFormData] = useState([]);
  const [product, setProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({ show: false, data: null });
  const [showResidencia, setShowResidencia] = useState(false);
  const [addCita, setAddCita] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selected, setSelected] = useState([]);
  const isAllSelected = oficinas.length > 0 && selected.length === oficinas.length;
  var current_date = new Date();
  const [endDate, setEndDate] = useState(new Date(current_date.getFullYear() + 1,current_date.getMonth(),current_date.getDate()));

  const getProductData = async () => {
    try {
      const data = await axios.get(
        "https://api.buscacita.es/getCitas?user=" + searchParams.get("user")
      );
      console.log(data.data);
      setProduct(data.data);
      const name_user = await axios.get(
        "https://api.buscacita.es/getClientName?user=" + searchParams.get("user")
      );
      {document.title = name_user.data}
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  const handleSubmit = () => {
    if (validateForm()) {
      // For now, just log the data. In real scenarios, you'd send this to a server.
      console.log(formData);
      fetch('https://api.buscacita.es/add_dgt?user=' + searchParams.get("user"), {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
          window.location.reload(true);
      })
      .catch((error) => {
          console.error('Error:', error);
          window.location.reload(true);
      });
    }
  };

  const validateForm = () => {
    var fulfilled = true;

    mandatoryFormData.forEach(field => {
      console.log(field)
      if ( (formData[field] === '') || (Array.isArray(formData[field]) && formData[field].length == 0) ) {
        fulfilled = false;
      }
    });

    return fulfilled;
  };

  const handleDropdownChange = (event) => {
    //setShowResidencia(true);
    const defaultData = {};
    const defaultMandatoryData = [];
      inputConfig[event.target.value].forEach(field => {
        defaultData[field.name] = field.type === "select" ? '' : '';
        //defaultData[field.name] = field.type === "select" ? field.options[0] : '';
        if (field.mandatory) {
          defaultMandatoryData.push(field.name)
        }
      });
    defaultMandatoryData.push("Provincia residencia")
    defaultMandatoryData.push("Provincia cita")
    defaultData["Provincia cita"] = []
    defaultData["FechaMIN"] = startDate.toLocaleDateString('en-GB')
    defaultData["FechaMAX"] = endDate.toLocaleDateString('en-GB')
    defaultData["Provincia residencia"] = ""
    defaultData["Observaciones"] = ""
    defaultData["Pais"] = event.target.value
    setFormData(defaultData);
    setmandatoryFormData(defaultMandatoryData);
    if (event.target.value == "Seleccionar...") {
      setAddCita(false);
    } else {
      setAddCita(true);
    }
    setSelectedValue(event.target.value);
  };

  const handleInputChange = (event) => {
    if (event.target.name === "Provincia cita" && event.target.value != "Seleccionar") {
      setFormData({
        ...formData,
        ["Provincia residencia"]: event.target.value
      });
      console.log(formData)
      if (provincias.includes(event.target.value)) {
        formData["Provincia residencia"] = event.target.value;
        setSelectedProvinceValue(event.target.value);
      }
      //{document.getElementByName("Provincia residencia").}
    }

    if (event.target.name === "Provincia residencia") {
      setSelectedProvinceValue(event.target.value);
    }

    setFormData({
      ...formData,
      [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value.trimStart()
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === oficinas.length ? [] : oficinas);
      setFormData({
        ...formData,
        [event.target.name]: selected.length === oficinas.length ? [] : oficinas
      });
      return;
    }
    if (event.target.name === "Provincia cita" && event.target.value[0] != "Seleccionar") {
      setFormData({
        ...formData,
        ["Provincia residencia"]: event.target.value[0]
      });
      console.log(formData)
      if (provincias.includes(event.target.value[0])) {
        formData["Provincia residencia"] = event.target.value[0];
        setSelectedProvinceValue(event.target.value[0]);
      }
      //{document.getElementByName("Provincia residencia").}
    }
    setSelected(value);
    setFormData({
      ...formData,
      [event.target.name]: value
    });
  };

  const handleViewAppointment = (appointment_data) => {
    console.log(appointment_data);
    var evento = {
        target : {
          value : appointment_data["Pais"]
        }
    }
    handleDropdownChange(evento)
    appointment_data["Provincia cita"] = [appointment_data["Provincia cita"]]
    setFormData({
      ...appointment_data
    });
    setSelectedProvinceValue(appointment_data["Provincia residencia"])
    setModal({ show: true, data: appointment_data });
  };

  const handleDeleteAppointment = (appointment_data) => {
    console.log(appointment_data);
    fetch('https://api.buscacita.es/deleteCita', {
      method: 'POST',
      mode: 'cors',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointment_data)
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data);
        window.location.reload(true);
    })
    .catch((error) => {
        console.error('Error:', error);
        window.location.reload(true);
    });
  };

  const handleClose = () => {
    setModal({ show: false, data: null });
  };

  var side = false;

  const searchParams = new URLSearchParams(document.location.search)

  /*
            /
                    <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < oficinas.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Seleccionar todos"
              />
            </MenuItem>
                      <select name="Provincia cita" value={formData["Provincia cita"]}  onChange={handleInputChange}>
                  <option key="Seleccionar" value="Seleccionar">Seleccionar</option>
            {oficinas.map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
          </select>
            
  */
  return (
    <div>
      <label>Selecciona el país a canjear</label>
      <div></div>
      <select name="selectPais" value={selectedValue} onChange={handleDropdownChange}>
        {Object.keys(inputConfig).map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', marginBottom: '8px' }}>

      <div className="grid-container">
      {addCita && <div className="input-wrapper">
          <label>Provincia cita</label>
          <Select
            labelId="mutiple-select-label"
            name="Provincia cita"
            multiple
            value={formData["Provincia cita"]}
            onChange={handleChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{maxWidth: 200}}>
          >
            {oficinas.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
        </Select>
        </div>}
        {inputConfig[selectedValue]?.map(field => (
          <div key={field.name} className="input-wrapper">
            <label>{field.name + (field.mandatory ? " (*)" : "")}</label>
            <div>
            {field.type === "select" ? (
              <div>
              <select style={{ maxWidth: "10px", width: "10%" }} name={field.name} value={formData[field.name]} onChange={handleInputChange}>
                {field.options.map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
              </select>
              </div>
            ) : field.type === "checkbox-group" ? (
              <fieldset>
                <legend>{field.label}</legend>
                {field.options.map(option => (
                  <div key={option.name}>
                    <input 
                      type="checkbox"
                      id={option.name}
                      name={option.name}
                      checked={formData[option.name]}
                      onChange={handleInputChange}
                    />
                    <label htmlFor={option.name}>{option.label}</label>
                  </div>
                ))}
              </fieldset>
            ) : (
              <TextField
                name={field.name}
                type="text"
                value={formData[field.name] || ''}
                onChange={handleInputChange}
                placeholder={field.placeholder}
              />
            )}
            </div>
          </div>
        ))}
          {addCita && <div id="selectResidencia" className="input-wrapper" style={{display: showResidencia ? "none" : "flex"}}>
          <label>Provincia residencia</label>
          <select name="Provincia residencia" value={selectedProvinceValue} onChange={handleInputChange}>
            <option key="Seleccionar" value="Seleccionar">Seleccionar</option>
            {provincias.map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
            {/* ...add more colors as needed */}
          </select>
        </div>}
        {addCita && <div id="FechaMIN" className="input-wrapper" style={{display: showResidencia ? "none" : "flex"}}>
          <label>A partir del día</label>
          <DatePicker
                selected={startDate}
                onSelect={(date) => setStartDate(date)}
                onChange={(date) => {var event = {"target":{"name": "FechaMIN", "value": date.toLocaleDateString('en-GB'), "type": "datepicker"}}; handleInputChange(event)}}
                onChangeRaw={event => {
                  const date = moment(event.target.value, "DD/MM/YYYY");
                  if (date.isValid()) {
                    setStartDate(date.toDate());
                  }
                }}
                name="FechaMIN"
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
        </div>}
        {addCita && <div id="FechaMAX" className="input-wrapper" style={{display: showResidencia ? "none" : "flex"}}>
          <label>Hasta el día</label>
          <DatePicker
                selected={endDate}
                onSelect={(date) => setEndDate(date)}
                onChange={(date) => {var event = {"target":{"name": "FechaMAX", "value": date.toLocaleDateString('en-GB'), "type": "datepicker"}}; handleInputChange(event)}}
                onChangeRaw={event => {
                  const date = moment(event.target.value, "DD/MM/YYYY");
                  if (date.isValid()) {
                    setEndDate(date.toDate());
                  }
                }}
                name="FechaMAX"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
              />
        </div>}
        {addCita && <div id="Observaciones" className="input-wrapper" style={{display: showResidencia ? "none" : "flex"}}>
          <label>Observaciones</label>
          <TextField
                name={"Observaciones"}
                type="text"
                value={formData["Observaciones"] || ''}
                onChange={handleInputChange}
                placeholder={""}
              />
        </div>}
      </div>
      {addCita && <Button variant="contained" color="primary" onClick={handleSubmit}>Añadir cita</Button>}
      <div></div>
      {modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
      </div>
      <TableContainer component={Paper} >
        <Table className={classes.table} aria-label="customized table" style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell width="15%"></StyledTableCell>
              <StyledTableCell width="10%">Provincia</StyledTableCell>
              <StyledTableCell width="10%">País</StyledTableCell>
              <StyledTableCell width="10%">NIF</StyledTableCell>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell>Fecha Min</StyledTableCell>
              <StyledTableCell>Fecha Max</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product
              .filter((item) => {
                if (search == "") {
                  return item;
                } else if (
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((item) => {
                var datos_item = item;
                return (
                  <StyledTableRow id={item.id}>
                    <StyledTableCell>
                    <Button id="deleteButton" variant="contained" color="primary" onClick={() => handleDeleteAppointment(item)}>
                      Borrar
                    </Button>
                    <Button id="viewButton" variant="contained" color="primary" onClick={() => handleViewAppointment(item)}>
                      Ver
                    </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["Provincia cita"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["Pais"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["NIF/NIE"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["Nombre"] + " " + item["Primer apellido"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["FechaMIN"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item["FechaMAX"]}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default App;
